import React from "react"
import { Link } from "gatsby"
import Clamp from "components/clamp"
import Hero from "components/hero"

export default () => {
  return (
    <>
      <Hero>Page not found</Hero>
      <Clamp inset>
        <p>
          <Link to="/">Home</Link>
        </p>
        <p>
          <Link to="/approach">Our approach</Link>
        </p>
        {/* <p>
          <Link to="/team">Our team</Link>
        </p> */}
        <p>
          <Link to="/companies">Our investments</Link>
        </p>
        <p>
          <Link to="/news">News</Link>
        </p>
      </Clamp>
    </>
  )
}
